import Cookies from 'js-cookie'

const CodeKey = 'string_code'

export function getCode() {
  return Cookies.get(CodeKey)
}
export function setCode(code) {
  return Cookies.set(CodeKey, code)
}

export function removeCode() {
  return Cookies.remove(CodeKey)
}

export function hasCode() {
  return !(Cookies.get(CodeKey) == undefined)
}
