<template>
  <div>
    <div>
      <van-field
        v-model="formData.userName"
        name="OA员工"
        label="OA员工"
        placeholder="OA员工"
        :disabled="true"
      />
      <van-field
        v-model="formData.phone"
        name="登录手机"
        label="登录手机"
        placeholder="登录手机"
        :disabled="true"
      />
      <van-field
        v-model="formData.position"
        name="职位"
        label="职位"
        placeholder="职位"
        :disabled="true"
      />
      <van-field
        v-model="formData.currentstationName"
        name="部门(站)"
        label="部门(站)"
        placeholder="部门(站)"
        :disabled="true"
      />
    </div>
    <div class="tips">
      绑定微信后，您将可以使用该微信进行签到和阅读文件等OA操作
    </div>

    <div style="margin: 16px; display: flex; justify-content: center">
      <van-button
        type="info"
        style="width:100%"
        v-if="isShow&&formData.wxState==0"
        @click="goWxAuth"
        >微信授权</van-button
      >
      <van-button
        type="info"
        style="width:100%"
        v-if="!isShow&&formData.wxState==0"
        @click="onReset"
        >确认绑定</van-button
      >
      <van-button
        type="info"
        style="width:100%"
        v-if="formData.wxState!=0"
        :disabled="true"
        >该账号已经绑定微信</van-button
      >
    </div>
  </div>
</template>

<script>
// import { register } from "@/api/employee";
import { Notify, Button, Icon, Field, Form } from "vant";
import { setCode } from "@/utils/common";
import { getinfo } from "@/api/common/user";
export default {
  name: "personalInfo",
  components: {
    [Notify.name]: Notify,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Field.name]: Field,
    [Form.name]: Form,
  },
  data() {
    return {
      formData: {
        userName: "胡英俊",
        phone: "15736109936",
        position: "站长",
        currentstationName: "沙溪收费站",
      },
      isShow: true,
    };
  },
  mounted() {
    this.getDetails();
    let obj = {};
    let newObj = this.$route.params.newObj;
    for (const key in newObj) {
      obj[key] = newObj[key];
    }
    this.formData = obj;
    this.loadInfo();
  },
  methods: {
    loadInfo() {
      let url = window.location.href;
      let index = url.indexOf("code=");
      let code = "";
      if (index >= 0) {
        url = url.substring(index).replace("#/", "");
        url = url.split("&")[0];
        code = url.substring(5);
      }
      if (code == "") {
        this.isShow = true;
      } else {
        this.isShow = false;
        setCode(code);
      }
    },
    getDetails() {
      this.isShow = false;
        getinfo().then((res) => {
          console.log("res", res);
          this.formData = res.data;
        });
    },
    //  微信授权
    goWxAuth() {
      this.isShow = false;
      let url = process.env.VUE_APP_WX_REDIRECTURL+"?p=chongzhi";
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd0ed98b5f2e590fa&redirect_uri=" +
        url +
        "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
    },
    // 确认绑定
    onReset() {
      
      this.$router.push({ name: "reset-password" });
    }
  }
};
</script>
<style lang="scss" scoped>
.tips {
  color: #333;
  padding: 20px 40px;
  line-height: 24px;
  font-size: 14px;
  text-align: center;
}
</style>